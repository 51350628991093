<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <!-- <v-row v-if="this.$store.getters.usuario.user_rol === 'Encargado'">
              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    locale="es"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row> -->

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="amount"
                  :append-icon="icons.mdiCashMultiple "
                  :rules="[Validaciones.requerido]"
                  label="Total $"
                  type="number"
                  dense
                  hide-details="true"
                  outlined
                  @keyup.enter="AssignLoan()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          depressed
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          :loading="isSaving"
          depressed
          @click=" AssignLoan()"
        >
          Confirmar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiEyeOutline,
  mdiCashMultiple,
} from '@mdi/js'

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1),
      menu: false,
      modal: false,
      dialog: false,
      personId: null,
      amount: 0,
      autoUpdate: true,
      title: 'Adelanto Empleado',

      icons: {

        mdiEyeOutline,
        mdiCashMultiple,

      },
      loadingButton: false,
      mensajeSnack: '',
      isSaving: false,
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
      },
    }
  },
  computed: {

    computedDateFormatted() {
      return this.formatDate(this.date)
    },

  },

  methods: {

    openDialog(item) {
      this.title = `Adelanto a ${item.name}`
      this.personId = item.personID
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    save(date) {
      this.$refs.menu.save(date)
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },

    async AssignLoan() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Salary/CreateLoan`
        const params = {
          personId: this.personId,
          amount: parseFloat(this.amount).toFixed(2),

        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              this.$store.dispatch('showSnack', {
                text: 'Adelanto realizado correctamente',
                color: 'success',
                timeout: 2500,
              })
              this.$refs.form.reset()
              this.closeDialog()
              this.isSaving = false
            },
          )
          .catch(e => {
            if (e.response) {
              if (e.response.data) { this.mensajeSnack = e.response.data } else { this.mensajeSnack = 'Error en la operación' }
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,

            })
            this.isSaving = false
          })
      }
    },

  },
}
</script>
