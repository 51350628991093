var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employeeData,"item-key":"id","sort-by":"name","search":_vm.buscar,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"loading":_vm.loadingEmployees,"loading-text":"Cargando Empleados...","no-data-text":"No hay registros para mostrar.","no-results-text":"No se encontraron registros en la búsqueda"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar por empleado / dni / email / etc.","outlined":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"size":"18","color":"success"},on:{"click":function($event){return _vm.AddAbsencetoEmployed(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCoffeeToGo)+" ")])]}}],null,true)},[_c('span',[_vm._v("Agergar Franco")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"info","size":"18"},on:{"click":function($event){return _vm.AddLoan(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCashSync)+" ")])]}}],null,true)},[_c('span',[_vm._v("Adelantos")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"warning","size":"18"},on:{"click":function($event){return _vm.openDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiHandCoinOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Liquidar Sueldo")])])]}}])}),_c('AbsencetoEmployed',{ref:"dialogholiday"}),_c('LoanDialog',{ref:"dialogloan"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[_c('v-container',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{ref:"dialogdate",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Seleccione el mes","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"es","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogdate.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","depressed":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isSaving,"depressed":""},on:{"click":function($event){return _vm.SalarySettlement(_vm.objectemployee)}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }