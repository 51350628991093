<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
  >
    <v-form
      ref="form"
      v-model="formValid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="shiftSelect"
                  :items="shift"
                  :append-icon="icons.mdiCalendarClock "
                  :rules="[Validaciones.requerido]"
                  menu-props="auto"
                  label="Turno afectado"
                ></v-select>
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="reasonSelect"
                  :items="reason"
                  :rules="[Validaciones.requerido]"
                  :append-icon="icons.mdiListBox"
                  menu-props="auto"
                  label="Motivo"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="description"
                  :append-icon="icons.mdiTagText"
                  :rules="[Validaciones.requerido]"
                  label="Descripción"
                  hide-spin-buttons
                  dense
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFrom"
                      label="Desde Fecha"
                      :append-icon="icons.mdiCalendarRangeOutline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    locale="es"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateTo"
                      label="Hasta Fecha"
                      :append-icon="icons.mdiCalendarRangeOutline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    locale="es"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              depressed
              @click="closeDialog()"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              :loading="isSaving"
              depressed
              @click="ConditionAbsence()"
            >
              Confirmar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiCalendarClock, mdiCalendarRangeOutline, mdiTagText, mdiListBox,
} from '@mdi/js'

export default {
  data() {
    return {
      dialog: false,
      description: null,
      dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      personId: 0,
      reason: ['Enfermedad', 'Franco', 'Vacaciones', 'Otros'],
      reasonSelect: null,
      shift: ['Turno Mañana', 'Turno Tarde', 'Turno Mañana y Turno Tarde'],
      shiftSelect: null,
      title: '',
      formValid: null,
      stateCheckInOut: '',
      turn: null,
      icons: {

        mdiCalendarClock,
        mdiCalendarRangeOutline,
        mdiTagText,
        mdiListBox,

      },
      mensajeSnack: '',
      isSaving: false,
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
      },
    }
  },

  watch: {
  },
  methods: {

    openDialog(item) {
      this.personId = item.personID
      this.title = `Asignar Franco a ${item.name}`

      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },
    ConditionAbsence() {
      if (this.$refs.form.validate()) {
        if (this.reasonSelect === 'Enfermedad') {
          this.stateCheckInOut = 'Ef'
        } else if (this.reasonSelect === 'Franco') {
          this.stateCheckInOut = 'Fr'
        } else if (this.reasonSelect === 'Vacaciones') {
          this.stateCheckInOut = 'Vc'
        } else {
          this.stateCheckInOut = 'Ot'
        }
        if (this.shiftSelect === 'Turno Mañana') {
          this.turn = 0
        }
        if (this.shiftSelect === 'Turno Tarde') {
          this.turn = 1
        }
        if (this.shiftSelect === 'Turno Mañana y Turno Tarde') {
          this.turn = 2
        }
      }
      console.log(this.stateCheckInOut)
      this.AddAbsencetoEmployee()
    },
    async AddAbsencetoEmployee() {
      this.isSaving = true
      this.url = `${this.$store.getters.urlBase}/api/Employee/AddAbsenceToEmployee`
      const params = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        personIdLogged: this.$store.getters.usuario.user_personid,
        personId: this.personId,
        description: this.description,
        stateCheckInOut: this.stateCheckInOut,
        turn: this.turn,
      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
      }
      await axios
        .post(this.url, params, config)
        .then(
          // eslint-disable-next-line no-return-assign
          response => {
            this.respuesta = response
            this.isSaving = false
            this.$store.dispatch('showSnack', {
              text: 'Franco Asignado con exito',
              color: 'success',
              timeout: 2500,
            })
            this.$refs.form.reset()
            this.closeDialog()
            this.isSaving = false
          },
        )
        .catch(e => {
          if (e.response) {
            if (e.response.data) { this.mensajeSnack = e.response.data } else { this.mensajeSnack = 'Error en la operación' }
          } else {
            this.mensajeSnack = 'Error de conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })
          this.isSaving = false
        })
    },

  },
}
</script>
