<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="employeeData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingEmployees"
      loading-text="Cargando Empleados..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por empleado / dni / email / etc."
            outlined
            dense
          ></v-text-field>

          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-icon
        size="18"
        class="me-3"
      >
        {{ icons.mdiPencil }}
      </v-icon> -->

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="18"
              color="success"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="AddAbsencetoEmployed(item)"
            >
              {{ icons.mdiCoffeeToGo }}
            </v-icon>
          </template>
          <span>Agergar Franco</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="info"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="AddLoan(item)"
            >
              {{ icons.mdiCashSync }}
            </v-icon>
          </template>
          <span>Adelantos</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="warning"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="warning"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="openDialog(item)"
            >
              {{ icons.mdiHandCoinOutline }}
            </v-icon>
          </template>
          <span>Liquidar Sueldo</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <AbsencetoEmployed
      ref="dialogholiday"
    ></AbsencetoEmployed>
    <LoanDialog ref="dialogloan"></LoanDialog>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-col
              cols="12"
            >
              <v-dialog
                ref="dialogdate"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Seleccione el mes"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  locale="es"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogdate.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                depressed
                @click="closeDialog()"
              >
                Cancelar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                :loading="isSaving"
                depressed
                @click="SalarySettlement(objectemployee)"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mdiCoffeeToGo, mdiCashSync, mdiHandCoinOutline, mdiEraser, mdiFaceWoman, mdiFaceMan, mdiFaceManShimmer, mdiAccountCard,
} from '@mdi/js'
import axios from 'axios'
import AbsencetoEmployed from '../AbsenceDialog.vue'
import LoanDialog from '../LoanDialog.vue'

export default {
  components: { AbsencetoEmployed, LoanDialog },
  data: () => ({

    title: '',
    loadingEmployees: false,
    icons: {
      mdiCoffeeToGo,
      mdiCashSync,
      mdiHandCoinOutline,
      mdiEraser,
      mdiFaceWoman,
      mdiFaceMan,
      mdiFaceManShimmer,
      mdiAccountCard,
    },
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,

    // new Date().toISOString().substr(0, 7),
    // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1),
    isSaving: false,
    objectemployee: null,
    Validaciones: {
      requerido: v => !!v || 'Campo Requerido',
    },
    dialog: false,
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'personID',
      },
      { text: 'DNI', value: 'dni' },
      // eslint-disable-next-line no-useless-concat
      { text: 'Empleado', value: 'name' },

      // { text: 'Sexo', value: 'sex' },
      { text: 'Telefono', value: 'phone' },
      { text: 'Cuenta', value: 'userAccount' },
      { text: 'Rol', value: 'rol' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    employeeData: [],
    buscar: '',
    empleadoAEliminar: null,
  }),

  mounted() {
    this.getListtEmployee()
  },
  methods: {

    AddAbsencetoEmployed(personId) {
      this.$refs.dialogholiday.openDialog(personId)
    },

    AddLoan(item) {
      this.$refs.dialogloan.openDialog(item)
    },
    closeDialog() {
      this.dialog = false
    },
    openDialog(item) {
      this.title = `Seleccione el mes a liquidar para ${item.name}`
      this.objectemployee = item
      this.personId = item.personID
      this.dialog = true
    },

    registerEmployee(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registeremployee', params: { id: tipoid } })
    },
    EditEmployee(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registeremployee', params: { id: tipoid } })
    },
    SalarySettlement(item) {
      console.log(this.date)

      this.$router.push({ name: 'salarysettlement', params: { id: item.personID, name: item.name, date: this.moment(this.date).startOf('month').format('YYYY-MM-DD') } })
    },

    async getListtEmployee() {
      this.loadingEmployees = true
      this.url = `${this.$store.getters.urlBase}/api/Employee`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
      // console.log(respuesta.data);
        this.employeeData = respuesta.data
        this.loadingEmployees = false

      // this.getListarDiasMantenimiento();
      } else {
      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
        this.loadingEmployees = false
      }
    },

  },
}
</script>
