<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Liquidaciones</v-card-title>
        <DataTableSalary></DataTableSalary>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTableSalary from './datatables/DataTableSalary.vue'

export default {
  components: {

    DataTableSalary,

  },
  setup() {
    return {}
  },
}
</script>
